//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.highlight{
  display: inline;
  background: rgba(theme-color('primary-2'),.4);
}
.brand-link{
  color: theme-color('primary-3');
  .icon{
    background-color: theme-color('primary-3');
  }
}
.brand-link:hover{
  color: rgba(theme-color('primary-3'),.8);
  .icon{
    background-color: rgba(theme-color('primary-3'),.8)
  }
}
